<template>
  <b-row class="content-header">
    <!-- Content Left -->
    <b-col
      v-if="breadcrumbs && breadcrumbs.length"
      class="content-header-left my-2 pr-0"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <div class="breadcrumb-wrapper">
            <b-breadcrumb class="px-0">
              <b-breadcrumb-item :to="collectiveHomeLocation">
                {{ translateTranslationTable (currentLocale ,collective.name) || collective.title }}
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="(item, index) in breadcrumbs"
                :key="index"
                :active="item.active"
                :to="getItemLocation(item)"
              >
                {{ getItemText(item) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <!-- TODO: This next block should not be part of this component. -->
    <!-- <template v-if="isHome">
      Content Left  Home Page
      <b-col class="content-header-left my-2">
        <b-row class="breadcrumbs-top">
          <b-col>
            <h2 class="d-none d-lg-block mb-0 pr-0">
              {{ collective.name || collective.title }}
            </h2>
            <h4 class="d-block d-lg-none mb-0 pr-0">
              {{ collective.name || collective.title }}
            </h4>
          </b-col>
        </b-row>
      </b-col>
    </template> -->
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  mixins: [GridStatusMixin],
  data() {
    return {
      isChatRoomVisible: false,
      exBread: null,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    breadcrumbs() {
      if (this.$route.meta?.pageTitle == null || this.isHome) {
        return null;
      }
      if (this.$store.getters['app/breadcrumbs']) {
        this.exBread = this.$store.getters['app/breadcrumbs'];
        if (this.exBread.length === 1) {
          let object = [
            {
              text: name,
              active: true,
            },
          ];
          let name = this.$t(this.$route.meta.pageTitle);

          const app = Object.values(this.$store.getters.apps?.apps || {}).find(
            (x) => x.name?.[this.$store.state.locale.currentLocale] === name,
          );
          if (
            app && (app.key === 'people' || app.key === 'entities')
            && ![null, 'noKey'].includes(this.$route.params?.typeKey)
          ) {
            const arr = this.$store.getters.types?.[app.key].unpaginated;
            const type = arr.find((x) => x.key === this.$route.params?.typeKey);

            if (app.customization?.[type?.key] != null) {
              name = app.customization[type.key]?.customizationName || app.customization[type.key];

              if (typeof (name) === 'object') {
                if (Object.keys(name).includes(
                  this.$store.getters.currentLocale,
                )) {
                  name = this.translateTranslationTable(this.$store.state.locale.currentLocale, name);
                  if (typeof (name) === 'object') {
                    name = Object.values(name)[0];
                  }
                } else {
                  name = Object.values(name)[0];
                }
              }
            } else if (
              Object.keys(type.namePlural).includes(
                this.$store.getters.currentLocale,
              )
            ) {
              name = this.translateTranslationTable(this.$store.state.locale.currentLocale, type.namePlural);
            } else {
              name = Object.values(type.namePlural)[0];
            }

            object = [
              {
                text: name,
                active: true,
              },
            ];

            return object;
          }
          if (app?.customizationName) {
            name = translateTranslationTable(
              this.$store.state.locale.currentLocale,
              app.customizationName,
            );
          }
          object = [
            {
              text: name,
              active: true,
            },
          ];
          this.exBread = name;
          return object;
        }
        return this.exBread;
      }
      let name = this.$t(this.$route.meta.pageTitle);
      if (name.length === 0 || typeof name !== 'string') {
        return null;
      }
      let app = Object.values(this.$store.getters.apps?.apps || {}).find(
        (x) => x.name?.[this.$store.state.locale.currentLocale] === name,
      );

      if (app == null) {
        app = Object.values(this.$store.getters.apps?.apps || {}).find(
          (x) => x.name?.[this.$store.state.locale.currentLocale].includes(name),
        );
      }
      if (app?.customizationName) {
        name = translateTranslationTable(
          this.$store.state.locale.currentLocale,
          app.customizationName,
        );
      }
      const object = [
        {
          text: name,
          active: true,
        },
      ];
      this.exBread = name;
      return object;
    },
    isHome() {
      return this.$route.name === 'Resumen';
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    collectiveHomeLocation() {
      let hash = this.$route.name;
      if (this.$route.meta?.goBackAnchor) {
        hash = typeof this.$route.meta.goBackAnchor === 'function'
          ? this.$route.meta.goBackAnchor(this.$route)
          : this.$route.meta.goBackAnchor;
      }
      return {
        name: 'Resumen',
        params: {
          communityId: this.collective.slug || undefined,
        },
        hash: `#${hash}`,
      };
    },
    isEditingLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
  },
  methods: {
    translateTranslationTable(locale, data) {
      return translateTranslationTable(locale, data);
    },
    getItemLocation(item) {
      if (!item.to) return null;

      return typeof item.to === 'function'
        ? item.to(item, this.$route)
        : {
          ...item.to,
          params: {
            communityId: this.$route.params.communityId,
            ...(item.to.params || {}),
          },
        };
    },
    getItemText(item) {
      let text = '';
      if (typeof item.text === 'function') {
        text = item.text(item, this.$route, this.$store);
      } else {
        text = item.text;
      }

      if (this.$te(text)) {
        if (this.$t(text).hasOwnProperty('title')) {
          const { title } = this.$t(text);
          return title.toLowerCase();
        }
        return this.$t(text);
      }
      return text;
    },
  },
};
</script>
